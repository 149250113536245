import { formatData } from 'app/utils/reducerUtils';
import {
  RECEIVE_PROVIDER_PROCEDURES,
  RECEIVE_PROVIDER,
  RECEIVE_PROVIDERS,
  UPDATE_PROVIDER_ROLLUPS,
} from '../../actions/providerActions';

const nullState = Object.freeze({
  cptCodes: [],
  drgCodes: [],
  loading: false,
  provider: null,
  providerProcedures: {},
  providers: [],
  pricingGroups: [],
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PROVIDERS: {
      const newProviders = action.providers.map(formatData);
      const providers = action.merge
        ? [...state.providers, ...newProviders]
        : newProviders;
      return { ...state, providers };
    }
    case RECEIVE_PROVIDER:
      return {
        ...state,
        provider: formatData(action.provider),
        loading: false,
      };
    case RECEIVE_PROVIDER_PROCEDURES:
      return {
        ...state,
        providerProcedures: {
          ...state.providerProcedures,
          [state.provider.id]: action.providerProcedures,
        },
      };
    case UPDATE_PROVIDER_ROLLUPS:
      return {
        ...state,
        provider: {
          ...state.provider,
          rollups: action.rollups,
        },
      };
    case 'RECEIVE_CPT_CODES': {
      const newCodes = action.cptCodes.map(formatData);
      const cptCodes = action.merge
        ? [...state.cptCodes, ...newCodes]
        : newCodes;
      return { ...state, cptCodes };
    }
    case 'RECEIVE_DRG_CODES':
      return { ...state, drgCodes: action.drgCodes.map(formatData) };
    case 'RECEIVE_PROVIDER_LOADING':
      return { ...state, loading: action.providerLoading };
    case 'RECEIVE_PRICING_GROUPS':
      return { ...state, pricingGroups: action.pricingGroups.map(formatData) };
    default:
      return state;
  }
};
